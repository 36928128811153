import { createContext } from 'solid-js';
import { gql } from '../graphql';
import { cachedQuery } from '../graphql/cached-get';
import type { SubscriptionProductsQuery } from '../graphql';
import type { Accessor } from 'solid-js';

export const AccessProductsCtx = createContext<Accessor<SubscriptionProductsQuery['products'] | undefined>>(
	() => undefined,
);

const query = gql(`query subscriptionProducts($promoCode: String) {
  products: troonCardSubscriptionProducts(promoCode: $promoCode) {
  	...AccessProductInfo
    id
    active
    subscriptionName
    description
    type
    discountAmount { displayValue, value }
    monthlyDiscountAmount { displayValue, value }
    subtotalAmount { displayValue, value }
    monthlySubtotalAmount { displayValue, value }
    totalAmount { displayValue, value }
    monthlyTotalAmount { displayValue, value }
    valueProps
    additionalValueProps
    isPopular
    autoRenew
    disclosure
  }
}`);

export const getAccessProducts = cachedQuery(query);
